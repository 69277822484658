import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import {filter, map, mergeMap} from 'rxjs/operators'
import {notifications} from './notifications'
import {Notification} from './types'

const clearNotifications = (action$, state$) =>
  action$.ofType(Router.PUSH)
  .pipe(
    mergeMap(() => notifications.map((n: Notification) => n.actions.close())),
  )

const buildEpic = (notification: Notification) => {
  return (action$, state$) => action$.ofType(...notification.trigger.actions).pipe(
    filter((action: any) => notification.trigger.filter(action, state$)),
    map((action: any) => notification.actions.open(action)),
  )
}

export const epic = ReduxObservable.combineEpics(
  clearNotifications,
  ...notifications.map((n: Notification) => buildEpic(n)),
)
